import {API} from 'aws-amplify';
import {BOTTLEHOUNDS_API_NAME} from './constants';
import {
  ProductModel, ProductSearchResultsModel
} from './openapi';
import { useQuery } from '@tanstack/react-query';
import { GetProductsLcboProductsGetRequest } from './openapi/apis';

export const LCBO_PRODUCTS_API_PATH = '/lcbo/products';


export const getProduct = (productId: string): Promise<ProductModel> =>
  API.get(BOTTLEHOUNDS_API_NAME, `${LCBO_PRODUCTS_API_PATH}/${productId}`, {})


export const searchProducts = (request: GetProductsLcboProductsGetRequest) =>
  API.get(
    BOTTLEHOUNDS_API_NAME,
    LCBO_PRODUCTS_API_PATH,
    {
      queryStringParameters: request,
    },
  ) as Promise<ProductSearchResultsModel>;

export const useGetProduct = (productId: string) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, productId],
    queryFn: () => getProduct(productId),
  });

export const useSearchProducts = (
  request: GetProductsLcboProductsGetRequest,
  enabled = false,
) =>
  useQuery({
    queryKey: [LCBO_PRODUCTS_API_PATH, request],
    queryFn: () => searchProducts(request),
    enabled,
  })
