import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

interface MapProps extends google.maps.MapOptions {
  children: ReactNode;
  style: {[key: string]: string};
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onIdle?: (map: google.maps.Map) => void;
  onMapLoaded?: () => void;
  radius: number;
}

const Map: FC<MapProps> = ({
  center,
  children,
  radius,
  style,
  onMapLoaded,
  ...options
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState<google.maps.Map>();

  const fitBounds = useCallback(() => {
    const bounds = new google.maps.Circle({radius, center}).getBounds();
    if (bounds && map) {
      map.fitBounds(bounds, 0);
    }
  }, [map, radius, center]);

  useEffect(() => {
    if (map && !mapLoaded && onMapLoaded) {
      setMapLoaded(true);
      onMapLoaded();
    }
  }, [mapLoaded, map]);

  useEffect(() => {
    if (ref.current && !map) {
      const map = new window.google.maps.Map(ref.current, options);
      map.addListener('bounds_changed', fitBounds);
      map.addListener('resize', fitBounds);
      setMap(map);
    }
  }, [ref, map, options, fitBounds]);

  useEffect(() => {
    if (map) {
      map.setOptions(options);
      setMap(map);
    }
  }, [map, options]);

  useEffect(() => {
    fitBounds();
  }, [fitBounds, map, radius]);

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          // @ts-ignore
          return React.cloneElement(child, {map});
        }
        return null;
      })?.filter(Boolean)}
    </>
  );
};

export default Map;
