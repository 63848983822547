import { Storage } from "aws-amplify";
import { useQuery } from "@tanstack/react-query";
import { WatchdogType } from "./openapi";
import { InventoryDiffModel } from "./types";

export const WATCHDOG_INVENTORY_KEY = 'WATCHDOG_INVENTORY';

type StorageObject = {
  Body: Blob;
}

export const getProductInventory = async (
  watchdogType: WatchdogType,
  productId: string,
): Promise<InventoryDiffModel | null> => {
  try {
    const storageObject = (await Storage.get(`${watchdogType}/${productId}`, {
      cacheControl: 'no-cache',
      download: true,
      level: 'public',
    })) as unknown as StorageObject;

    if (!storageObject) {
      return null;
    }

    return new Promise<InventoryDiffModel | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = event => {
        if (event?.target?.readyState == FileReader.DONE) {
          const inventoryDiff = JSON.parse(
            event.target.result as string
          ) as InventoryDiffModel;

          // const lastModified = new Date(inventoryDiff.timestamp);
          // if (
          //   !inventoryDiff.timestamp ||
          //   new Date().getTime() - lastModified.getTime() > TEN_MINUTES_IN_MS
          // ) {
          //   resolve(null);
          //   return;
          // }
          resolve(inventoryDiff);
        } else {
          reject();
        }
      };
      reader.onerror = reject;
      reader.readAsText(storageObject.Body as Blob);
    });
  } catch (reason: any) {
    if (reason.name == 'NoSuchKey') {
      return null;
    }
    throw reason;
  }
};

export const useGetProductInventory = (
  watchdogType: WatchdogType,
  productId: string,
) =>
  useQuery({
    queryKey: ['WATCHDOG_INVENTORY_KEY', watchdogType, productId],
    queryFn: () => getProductInventory(watchdogType, productId),
    refetchInterval: () => {
      return 270000 + Math.floor(Math.random() * 30000 + 1);
    },
    refetchIntervalInBackground: true,
  });
