export const BOTTLEHOUNDS_API_NAME = 'bottlehounds';

export const BOTTLEHOUNDS_API_LCBO_PATH = '/lcbo/';
export const BOTTLEHOUNDS_API_LCBO_GET_PATH = `${BOTTLEHOUNDS_API_LCBO_PATH}get`;
export const BOTTLEHOUNDS_API_LCBO_SEARCH_PATH = `${BOTTLEHOUNDS_API_LCBO_PATH}search`;

export const BOTTLEHOUNDS_API_WATCHDOGS_PATH = '/watchdogs/';
export const BOTTLEHOUNDS_API_WATCHDOGS_ADD_PATH = `${BOTTLEHOUNDS_API_WATCHDOGS_PATH}add`;
export const BOTTLEHOUNDS_API_WATCHDOGS_DELETE_PATH = `${BOTTLEHOUNDS_API_WATCHDOGS_PATH}delete`;

export const BOTTLEHOUNDS_API_MAPS_PATH = '/maps/';
export const BOTTLEHOUNDS_API_MAPS_GET_PATH = `${BOTTLEHOUNDS_API_MAPS_PATH}get`;
export const BOTTLEHOUNDS_API_MAPS_SEARCH_PATH = `${BOTTLEHOUNDS_API_MAPS_PATH}search`;

export const BOTTLEHOUNDS_API_USER_PATH = '/user/';
export const BOTTLEHOUNDS_API_USER_UPDATE_PATH = `${BOTTLEHOUNDS_API_USER_PATH}update`;

export const WATCHDOGS_ATTRIBUTE_NAME = 'custom:watchdogs';
