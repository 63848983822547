import { SubscriptionDetailsModel } from "../api/openapi";


const ACTIVE_SUBSCRIPTION_STATUSES = ['active', 'trialing']


export const isUserActive = (
  subscription: SubscriptionDetailsModel
): boolean => {
  const currentPeriodEnd = (
    subscription?.currentPeriodEnd ?
    Date.parse(String(subscription?.currentPeriodEnd)) : null
  );
  const trialEnd = (
    subscription?.trialEnd ?
    Date.parse(String(subscription?.trialEnd)) : null
  );

  const expirationDate =
    !currentPeriodEnd ||
    (trialEnd && currentPeriodEnd && trialEnd > currentPeriodEnd)
      ? trialEnd
      : currentPeriodEnd;

  return !!(
    ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription?.subscriptionStatus) &&
    expirationDate && expirationDate > Date.now()
  );
}
