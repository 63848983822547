import { useState } from "react";
import { Checkbox, Subheading, Text, Title, useTheme } from "react-native-paper";
import { Auth } from "aws-amplify";
import { StyleSheet, View } from "react-native";
import { TextInput } from "react-native-paper";
import { AuthStackScreenProps } from "./types";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import BottleButton from "../../components/BottleButton";
import BottleLink from "../../components/BottleLink";
import VerifyEmail from "./VerifyEmail";
import AuthScreen from "./AuthScreen";
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from "../../constants";


const SignUp = ({ navigation }: AuthStackScreenProps<"SignUp">) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);

  const { colors } = useTheme();

  const signUpDisabled =
    !agreedToTerms ||
    !email ||
    !password ||
    password !== confirmPassword ||
    loading;

  const confirmPasswordError = !!(
    confirmPassword && confirmPassword !== password
  );

  const submit = () => {
    setLoading(true);
    setError("");
    setEmailError(false);
    setPasswordError(false);

    Auth.signUp({
      username: email,
      password,
      attributes: {email},
    })
      .then(() => setShowVerifyEmail(true))
      .catch(error => {
        setLoading(false);
        switch (error.code) {
          case 'InvalidPasswordException':
            setError(getLocaleString('invalidPassword'));
            setPasswordError(true);
            setPassword('');
            setConfirmPassword('');
            break;
          case 'InvalidParameterException':
            if (error.message == 'Invalid email address format.') {
              setError(getLocaleString('invalidEmail'));
              setEmailError(true);
            }
            break;
          case 'UsernameExistsException':
            setError(getLocaleString('userExists'));
            setEmailError(true);
            break;
          default:
            setError(getLocaleString('swwSignUp'));
            break;
        }
      });
  };

  return showVerifyEmail ? (
    <VerifyEmail email={email} password={password} navigation={navigation} />
  ) : (
    <AuthScreen>
      <Title style={styles.text}>
        {getLocaleString('pleaseEnterAFewDetails')}
      </Title>

      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="email"
        autoFocus={emailError}
        dense={true}
        error={emailError}
        keyboardType="email-address"
        label={getLocaleString('email')}
        mode="outlined"
        onChangeText={setEmail}
        returnKeyType="next"
        value={email}
        disabled={loading}
        style={styles.textInput}
      />

      <TextInput
        autoComplete="off"
        autoFocus={passwordError}
        dense={true}
        error={passwordError}
        label={getLocaleString('password')}
        mode="outlined"
        onChangeText={setPassword}
        secureTextEntry={true}
        value={password}
        disabled={loading}
        style={styles.textInput}
      />

      <TextInput
        autoComplete="off"
        dense={true}
        label={getLocaleString('confirmPassword')}
        mode="outlined"
        onChangeText={setConfirmPassword}
        secureTextEntry={true}
        value={confirmPassword}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        style={styles.textInput}
        error={confirmPasswordError}
      />

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginTop: 20,
        }}>
        <View style={{marginRight: 4}}>
          <Checkbox
            status={agreedToTerms ? 'checked' : 'unchecked'}
            onPress={() => setAgreedToTerms(!agreedToTerms)}
          />
        </View>

        <Text variant="titleMedium">
          {getLocaleNodes('bySigningUp', {
            tac: (
              <BottleLink
                variant="titleMedium"
                href={TERMS_OF_USE_URL}>
                {getLocaleString('termsOfUse')}
              </BottleLink>
            ),
            privacy: (
              <BottleLink
                variant="titleMedium"
                href={PRIVACY_POLICY_URL}>
                {getLocaleString('privacyPolicy')}
              </BottleLink>
            ),
          })}
        </Text>
      </View>

      <BottleButton
        disabled={signUpDisabled}
        mode="contained"
        onPress={submit}
        style={styles.button}>
        {getLocaleString('signUp')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  text: {
    textAlign: 'center',
    marginBottom: 10,
  },
  textInput: {
    marginBottom: 5,
  },
  button: {
    marginTop: 15,
  },
});

export default SignUp;
