import Alerts from './Alerts';
import { WelcomeStepKey } from './types';
import Search from './Search';
import Location from './Location';
import { useState } from 'react';
import LcboSpecialAlerts from './LcboSpecialAlerts';
import BottleScreen from '../../components/BottleScreen';

type WelcomeProps = {
  initialStep?: WelcomeStepKey;
}

const Welcome = ({initialStep = "search"}: WelcomeProps) => {
  const [step, setStep] = useState<WelcomeStepKey>(initialStep);

  const getStep = () => {switch (step) {
    default:
    case 'search':
      return <Search setStep={setStep} />;
    case 'location':
      return <Location setStep={setStep} />;
    case 'alerts':
      return <Alerts setStep={setStep} />;
    case 'lcboSpecialAlerts':
      return <LcboSpecialAlerts />;
  }};

  return <BottleScreen>
    {getStep()}
  </BottleScreen>
}

export default Welcome;
