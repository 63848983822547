import { Icon, Text, useTheme } from 'react-native-paper';
import { View } from 'react-native';

interface PlanChipProps {
  text: string;
  icon: string;
}

const PlanChip = ({text, icon}: PlanChipProps) => {
  const theme = useTheme();
  return <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 4,
    }}>
    <Icon size={20} source={icon} color={theme.colors.primary} />
    <Text variant="titleMedium" style={{marginLeft: 5}}>
      {text}
    </Text>
  </View>
};

export default PlanChip;
