import { API } from 'aws-amplify';
import { BOTTLEHOUNDS_API_NAME } from './constants';
import { SettingsRequestModel, SettingsResponseModel } from './openapi';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export const SETTINGS_API_PATH = '/settings'

export const getSettings = () =>
  API.get(
    BOTTLEHOUNDS_API_NAME,
    SETTINGS_API_PATH,
    {},
  ) as Promise<SettingsResponseModel>;

export const updateSettings = (
  request: SettingsRequestModel,
): Promise<SettingsResponseModel> =>
  API.patch(BOTTLEHOUNDS_API_NAME, SETTINGS_API_PATH, {body: request});

export const useGetSettings = () =>
  useQuery<SettingsResponseModel>({
    queryKey: [SETTINGS_API_PATH],
    queryFn: getSettings,
  });

export const useUpdateSettings = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSettings,
    onSuccess: () =>
      queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  });
}
