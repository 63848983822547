import { StyleSheet, View } from "react-native";
import { Switch, SwitchProps, Text } from "react-native-paper";

type BottleSwitchProps = {
  title: string;
  description: string;
  switchProps?: SwitchProps;
}

const BottleSwitch = ({title, description, switchProps}: BottleSwitchProps) => {
  return (
    <View style={styles.root}>
      <View style={styles.switch}>
        <Text variant="titleLarge">{title}</Text>
        <Switch {...switchProps} />
      </View>
      <Text variant="titleMedium">{description}</Text>
    </View>
  )
};

const styles = StyleSheet.create({
  root: {
    marginBottom: 8,
    maxWidth: 400,
    width: '100%',
  },
  switch: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 8,
  },
});

export default BottleSwitch;