import { useState } from "react";
import { Auth } from "aws-amplify";
import { StyleSheet } from "react-native";
import { Title, TextInput, Subheading, useTheme } from "react-native-paper";
import { AuthStackScreenProps } from "../types";
import { getLocaleString } from "../../../utils/getLocaleString";
import BottleButton from "../../../components/BottleButton";
import ResetPassword from "./ResetPassword";
import AuthScreen from "../AuthScreen";


const ForgotPassword = ({ navigation }: AuthStackScreenProps<"ForgotPassword">) => {
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);

  const { colors } = useTheme();

  const resetDisabled = !email || loading;

  const submit = () => {
    setLoading(true);
    setError("");
    Auth.forgotPassword(email)
      .then(() => setCodeSent(true))
      .catch(error => {
        setError(getLocaleString('sww'));
      })
      .finally(() => setLoading(false));
  };

  return codeSent ? (
    <ResetPassword email={email} navigation={navigation} />
  ) : (
    <AuthScreen>
      <Title style={styles.text}>{getLocaleString('enterResetEmail')}</Title>

      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="username"
        autoFocus={true}
        dense={true}
        label={getLocaleString('email')}
        mode="outlined"
        onChangeText={setEmail}
        value={email}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        style={styles.field}
      />

      <BottleButton disabled={resetDisabled} mode="contained" onPress={submit} loading={loading}>
        {getLocaleString('sendEmail')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  container: {
    display: "flex",
  },
  field: {
    marginBottom: 10,
  },
  text: {
    textAlign: "center",
  },
});

export default ForgotPassword;
