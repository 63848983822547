import { useState } from 'react';
import { Linking, Text } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { DataTable } from 'react-native-paper';

interface InventoryTableProps {
  inventory: StoreInventoryModel[];
};

export const InventoryTable = ({ inventory = [] }: InventoryTableProps) => {
  const [page, setPage] = useState<number>(0);
  const numberOfItemsPerPage = 50;
  const from = page * numberOfItemsPerPage;
  const to = Math.min((page + 1) * numberOfItemsPerPage, inventory.length);

  return <DataTable>
    <DataTable.Header>
      <DataTable.Title>Inventory</DataTable.Title>
      <DataTable.Title>City</DataTable.Title>
      <DataTable.Title>Address</DataTable.Title>
      <DataTable.Title>Intersection</DataTable.Title>
      <DataTable.Title>Phone</DataTable.Title>
    </DataTable.Header>

    {
      inventory.map(row =>
        <DataTable.Row key={row.storeNumber}>
          <DataTable.Cell>{row.inventory}</DataTable.Cell>
          <DataTable.Cell>{row.city}</DataTable.Cell>
          <DataTable.Cell>{row.address1}<br/>{row.address2}</DataTable.Cell>
          <DataTable.Cell>{row.intersection}</DataTable.Cell>
          <DataTable.Cell>
            <TouchableOpacity onPress={() => Linking.openURL(`tel:${row.phone}`)}>
              <Text>{row.phone}</Text>
            </TouchableOpacity>
          </DataTable.Cell>
        </DataTable.Row>
      )
    }

    <DataTable.Pagination
      page={page}
      numberOfPages={Math.ceil(inventory.length / numberOfItemsPerPage)}
      onPageChange={page => setPage(page)}
      label={`${from + 1}-${to} of ${inventory.length}`}
      showFastPaginationControls
      numberOfItemsPerPage={50}
    />
  </DataTable>
}
