import {API} from 'aws-amplify';
import {BOTTLEHOUNDS_API_NAME} from './constants';
import {useMutation, useQuery} from '@tanstack/react-query';
import {
  SubscriptionCheckoutResponseModel,
  SubscriptionConfigResponseModel,
  SubscriptionCreateResponseModel,
  SubscriptionPlanEnum,
  SubscriptionPlansResponseModel,
  SubscriptionPortalSessionModel,
} from './openapi';

export const SUBSCRIPTIONS_API_PATH = '/subscriptions';
const SUBSCRIPTIONS_CONFIG_PATH = `${SUBSCRIPTIONS_API_PATH}/config`;
const SUBSCRIPTIONS_PLANS_PATH = `${SUBSCRIPTIONS_API_PATH}/plans`;

export const getSubscriptionConfig = () =>
  API.get(
    BOTTLEHOUNDS_API_NAME,
    SUBSCRIPTIONS_CONFIG_PATH,
    {},
  ) as Promise<SubscriptionConfigResponseModel>;

export const getSubscriptionPlans = () =>
  API.get(
    BOTTLEHOUNDS_API_NAME,
    SUBSCRIPTIONS_PLANS_PATH,
    {},
  ) as Promise<SubscriptionPlansResponseModel>;

export const getSubscriptionPortalSession = () =>
  API.get(
    BOTTLEHOUNDS_API_NAME, `${SUBSCRIPTIONS_API_PATH}/portal`, {}
  ) as Promise<SubscriptionPortalSessionModel>;

export const createSubscription = (plan: SubscriptionPlanEnum) =>
  API.post(BOTTLEHOUNDS_API_NAME, SUBSCRIPTIONS_API_PATH, {
    body: {plan},
  }) as Promise<SubscriptionCreateResponseModel>;

export const createSubscriptionCheckoutSession = (plan: SubscriptionPlanEnum) =>
  API.post(BOTTLEHOUNDS_API_NAME, `${SUBSCRIPTIONS_API_PATH}/checkout`, {
    body: {plan},
  }) as Promise<SubscriptionCheckoutResponseModel>;

export const useCreateSubscription = () =>
  useMutation({
    mutationFn: createSubscription,
  });

export const useGetSubscriptionPortalSession = () =>
  useQuery({
    queryKey: [],
    queryFn: getSubscriptionPortalSession,
  });

export const useGetSubscriptionConfig = () =>
  useQuery({
    queryKey: [SUBSCRIPTIONS_CONFIG_PATH],
    queryFn: getSubscriptionConfig,
  });

export const useCreateSubscriptionCheckoutSession = () =>
  useMutation({
    mutationFn: createSubscriptionCheckoutSession,
  });

export const useGetSubscriptionPlans = () =>
  useQuery({
    queryKey: [SUBSCRIPTIONS_PLANS_PATH],
    queryFn: getSubscriptionPlans,
  });
