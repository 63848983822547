import { Platform } from 'react-native';
import {Text, TextProps, useTheme} from 'react-native-paper';
import AndroidBottleTitle from './AndroidBottleTitle';

const BottleTitle = ({children, ...props}: TextProps<string>) => {
  const theme = useTheme();
  return (
    Platform.OS === "android" ?
    <AndroidBottleTitle {...props}>{children}</AndroidBottleTitle> :
    <Text
      {...props}
      style={{
        textDecorationColor: theme.colors.primary,
        textDecorationStyle: 'solid',
        textDecorationLine: 'underline',
        ...(props.style ? (props.style as object) : {}),
      }}>
      {children}
    </Text>
  );
};

export default BottleTitle;
