import { useState } from "react";
import { Auth } from "aws-amplify";
import { StyleSheet } from "react-native";
import { Title, TextInput, Subheading, useTheme } from "react-native-paper";
import { getLocaleString } from "../../utils/getLocaleString";
import BottleButton from "../../components/BottleButton";
import { StackNavigationProp } from "@react-navigation/stack";
import { AuthStackParamList } from "./types";
import AuthScreen from "./AuthScreen";


type VerifyEmailProps = {
  email: string;
  password: string;
  navigation: StackNavigationProp<AuthStackParamList>;
};


const VerifyEmail = ({email, password, navigation}: VerifyEmailProps) => {
  const [error, setError] = useState('');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);

  const {colors} = useTheme();

  const verifyDisabled = !code || loading;

  const submit = () => {
    setLoading(true);
    setError('');
    Auth.confirmSignUp(email, code)
      .then(() => Auth.signIn(email, password))
      .catch(error => {
        switch (error.code) {
          case 'CodeMismatchException':
            setError(getLocaleString('invalidVerificationCode'));
            setCode('');
            break;
          default:
            navigation.push('SignIn', {
              error: getLocaleString('sww'),
            });
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthScreen>
      <Title style={styles.text}>
        {getLocaleString('checkEmailForVerificationCode')}
      </Title>

      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="off"
        autoFocus={true}
        dense={true}
        label={getLocaleString('code')}
        mode="outlined"
        onChangeText={setCode}
        value={code}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        style={styles.field}
      />

      <BottleButton disabled={verifyDisabled} mode="contained" onPress={submit}>
        {getLocaleString('verifyEmail')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  field: {
    marginBottom: 10,
  },
  text: {
    textAlign: "center",
  },
});

export default VerifyEmail;
