import {Platform} from 'react-native';
import {MapProps} from './types';
import MobileMap from './MobileMap';
import WebMap from './WebMap';

const Map = ({radius, region, onMapLoaded}: MapProps) => {
  return Platform.OS === 'web' ? (
    <WebMap radius={radius} region={region} onMapLoaded={onMapLoaded} />
  ) : (
    <MobileMap radius={radius} region={region} onMapLoaded={onMapLoaded} />
  );
};

export default Map;
