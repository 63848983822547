import { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList, View, StyleSheet, ListRenderItem } from "react-native";
import { Dialog, Modal } from "react-native-paper";
import { InventoryTable } from "../../components/InventoryTable";
import { HomeStackScreenProps } from "../Home/types";
import { useGetSettings } from "../../api/settings";
import { WatchdogSettingsModel } from "../../api/openapi";
import { WatchdogCard } from "./WatchdogCard";
import { getLocaleString } from "../../utils/getLocaleString";
import BottleLoader from "../../components/BottleLoader";
import BottleButton from "../../components/BottleButton";


const Watchdogs = ({ navigation }: HomeStackScreenProps<'Watchdogs'>) => {
  const [inventoryVisible, setInventoryVisible] = useState(false);
  const [inventory, setInventory] = useState<StoreInventoryModel[]>([]);
  const { data: settings, isLoading } = useGetSettings();
  
  const hideInventory = () => setInventoryVisible(false);
  const onShowInventory = (inventory: StoreInventoryModel[]) => {
    setInventory(inventory);
    setInventoryVisible(true);
  };

  const renderItem: ListRenderItem<WatchdogSettingsModel> = ({ item }) => {
    return (
      <WatchdogCard
        onShowInventory={onShowInventory}
        watchdog={item}
      />
    );
  };

  return (
    <SafeAreaView style={styles.container}>
      {isLoading ? (
        <BottleLoader />
      ) : (
        <>
          {/* <Portal> */}
          <Modal visible={inventoryVisible} onDismiss={hideInventory}>
            <InventoryTable inventory={inventory} />
          </Modal>

          <FlatList
            data={settings?.watchdogs}
            renderItem={renderItem}
            keyExtractor={item => item.id as string}
            ListEmptyComponent={BottleLoader}
            style={styles.results}
          />

          <View style={styles.addButton}>
            <BottleButton
              icon="plus"
              mode="contained"
              onPress={() => {
                navigation.push('Search');
              }}>
              {getLocaleString('addWatchdog')}
            </BottleButton>
          </View>

          <Dialog visible={inventoryVisible} onDismiss={hideInventory}>
            <Dialog.Content>
              <InventoryTable inventory={[]} />
            </Dialog.Content>
          </Dialog>
          {/* </Portal> */}
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
  },
  addButton: {
    marginTop: 10,
    marginBottom: 15,
  },
  results: {
    flex: 1
  },
  loading: {
    margin: "auto",
  }
});

export default Watchdogs;
