import {Text, TextProps, useTheme} from 'react-native-paper';

const Keyword = ({children, style, ...props}: TextProps<string>) => {
  const theme = useTheme();
  return (
    <Text
      {...props}
      style={{
        color: theme.colors.primary,
        fontFamily: '"Roboto Mono", Menlo',
        ...(style ? (style as object) : {}),
      }}>
      {children}
    </Text>
  );
};

export default Keyword;
