import { VariantProp } from "react-native-paper/lib/typescript/components/Typography/types";
import Keyword from "./Keyword";

interface WatchdogWordProps {
  variant: VariantProp<string>;
}

const WatchdogWord = ({variant}: WatchdogWordProps) =>
  <Keyword variant={variant}>Watchdog</Keyword>;

export default WatchdogWord;
