import { Button, ButtonProps } from "react-native-paper";

const BottleButton = ({children, ...props}: ButtonProps) => (
  <Button
    {...props}
    labelStyle={{
      fontSize: 18,
      ...(props.labelStyle ? (props.labelStyle as object) : {}),
    }}>
    {children}
  </Button>
);

export default BottleButton;
