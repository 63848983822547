import { Searchbar as PaperSearchBar, SearchbarProps } from "react-native-paper";

const Searchbar = (props: SearchbarProps) => (<PaperSearchBar
  {...props}
  style={{
    ...{
      backgroundColor: 'black',
      borderBottomWidth: 1,
      borderBottomColor: 'white',
      marginBottom: 8,
    },
    ...(typeof props.style === 'object' ? props.style : {}),
  }}
/>);

export default Searchbar;
