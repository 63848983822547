import { useState } from "react";
import { StyleSheet, View } from "react-native";
import {
  IconButton, Modal, Portal, Surface, Text
} from "react-native-paper";
import { ProductModel } from "../../api/openapi";
import { useDeleteWatchdog } from "../../api/watchdogs";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import Keyword from "../../components/Keyword";
import BottleButton from "../../components/BottleButton";
import { useAppTheme } from "../../theme";

interface DeleteWatchdogButtonProps {
  product: ProductModel;
}
const DeleteWatchdogButton = ({
  product,
}: DeleteWatchdogButtonProps) => {
  const theme = useAppTheme();
  const [visible, setVisible] = useState(false);

  const deleteWatchdog = useDeleteWatchdog()

  const deleteProduct = () => {
    deleteWatchdog.mutate(product.id as string)
  };

  return (
    <>
      <IconButton
        iconColor={theme.colors.error}
        style={styles.button}
        icon="delete"
        accessibilityLabel={getLocaleString('delete')}
        onPress={() => setVisible(true)}
        disabled={deleteWatchdog.isLoading}
      />

      <Portal>
        <Modal
          visible={visible}
          onDismiss={() => setVisible(false)}
          style={styles.modal}>
          <Surface
            style={{
              backgroundColor: theme.colors.elevation.level2,
              borderTopColor: theme.colors.primary,
              borderTopWidth: 4,
              ...styles.content,
            }}>
            <Text style={styles.title} variant="titleLarge">
              {getLocaleNodes('deleteFromList', {
                watchdog: (
                  <Keyword key={product.id} variant="titleLarge">
                    {product.name}
                  </Keyword>
                ),
              })}
            </Text>

            <View style={styles.actions}>
              <BottleButton
                textColor={theme.colors.secondary}
                disabled={deleteWatchdog.isLoading}
                onPress={() => setVisible(false)}>
                {getLocaleString('cancel')}
              </BottleButton>
              <BottleButton
                textColor={theme.colors.error}
                disabled={deleteWatchdog.isLoading}
                loading={deleteWatchdog.isLoading}
                onPress={deleteProduct}>
                {getLocaleString('delete')}
              </BottleButton>
            </View>
          </Surface>
        </Modal>
      </Portal>
    </>
  );
};

const styles = StyleSheet.create({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20
  },
  button: {
    margin: 0,
  },
  content: {
    width: '100%',
    maxWidth: 600,
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 12,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginBottom: 10,
  },
});

export default DeleteWatchdogButton;
