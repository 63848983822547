import {useState} from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {getLocaleNodes, getLocaleString} from '../../utils/getLocaleString';
import {WelcomeScreenProps} from './types';
import BottleSearch from '../../components/BottleSearch';
import WatchdogWord from '../../components/WatchdogWord';
import Keyword from '../../components/Keyword';
import BottleTitle from '../../components/BottleTitle';

const Search = ({
  setStep,
}: WelcomeScreenProps) => {
  const [searchRan, setSearchRan] = useState<boolean>(false);

  const onAdd = () => setStep('location');
  const onSearch = () => setSearchRan(true);

  return (
    <View style={styles.root}>
      {!searchRan && (
        <View style={styles.heading}>
          <BottleTitle style={styles.welcome} variant="displaySmall">
            {getLocaleString('welcomeToBottleHounds')}
          </BottleTitle>

          <Text style={styles.text} variant="headlineSmall">
            {getLocaleString('searchForTheBottleOfYourDreamsBelow')}
          </Text>
        </View>
      )}

      {searchRan && (
        <>
          <View style={styles.searchHeading}>
            <BottleTitle style={styles.text} variant="displaySmall">
              {getLocaleNodes('enterWatchdog', {
                watchdog: <WatchdogWord variant="displaySmall" />,
              })}
            </BottleTitle>
          </View>

          <Text style={styles.justify} variant="headlineSmall">
            {getLocaleNodes('aWatchdogIsAResourceDedicated', {
              track: (
                <Keyword variant="titleLarge">
                  {getLocaleString('track')}
                </Keyword>
              ),
              watchdog: <WatchdogWord variant="headlineSmall" />,
            })}
          </Text>
        </>
      )}

      <View
        style={{
          width: '100%',
          maxWidth: 520,
          flex: 2,
          marginTop: 12,
          display: 'flex',
          flexDirection: 'row',
        }}>
        <BottleSearch onboarding={true} onAdd={onAdd} onSearch={onSearch} />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    width: '100%',
  },
  welcome: {
    textAlign: 'center',
    marginBottom: 30,
  },
  heading: {
    justifyContent: 'center',
    marginBottom: 40,
    marginTop: 20,
  },
  searchHeading: {
    marginTop: 20,
    marginBottom: 30,
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
  },
  justify: {
    textAlign: 'justify',
  }
});

export default Search;
