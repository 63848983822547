import { Linking } from "react-native";
import { Text, TextProps } from "react-native-paper";
import { useAppTheme } from "../theme";

type BottleLinkProps = {
  href: string;
} & TextProps<string>

const BottleLink = ({children, href, ...props}: BottleLinkProps) => {

  const theme = useAppTheme();

  return (
    <Text
      onPress={() => Linking.openURL(href)}
      style={{
        color: theme.colors.primary,
        textDecorationLine: 'underline',
        ...(props.style ? (props.style as object) : {}),
      }}
      {...props}>
      {children}
    </Text>
  );
};

export default BottleLink;
