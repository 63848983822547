import {IS_MOBILE} from '../../constants';

const WEB_LINKS =
  window.location?.hostname === 'localhost'
    ? ['http://localhost:8080/']
    : process.env.NODE_ENV === 'production'
    ? ['https://app.bottlehounds.ca/']
    : ['https://app.dev.bottlehounds.ca/'];

export const LINKS = 
  IS_MOBILE ? ['bottlehounds://'].concat(WEB_LINKS) : WEB_LINKS;
