import {useState} from 'react';
import {Icon, List, ListItemProps, Text, useTheme} from 'react-native-paper';
import {ScrollView, StyleSheet, View} from 'react-native';
import {getLocaleString} from '../../utils/getLocaleString';
import {AlertSettingsModel} from '../../api/openapi/models';
import {useGetSettings, useUpdateSettings} from '../../api/settings';
import BottleTitle from '../../components/BottleTitle';
import BottleSwitch from '../../components/BottleSwitch';
import BottleButton from '../../components/BottleButton';

const ListItem = (props: ListItemProps) => (
  <List.Item
    titleStyle={{
      marginBottom: 1,
    }}
    {...props}
    style={{
      marginBottom: 4,
      padding: 0,
      paddingLeft: 4,
      paddingRight: 4,
      ...(props.style as object),
    }}
  />
);

const LcboSpecialAlerts = () => {
  const {data: settings} = useGetSettings();
  const [state, setState] = useState<AlertSettingsModel>(
    settings?.lcboSpecialAlerts ?? {
      pushNotifications: settings?.alertSettings?.pushNotifications ?? true,
      emailAlerts: settings?.alertSettings?.emailAlerts ?? false,
    },
  );

  const theme = useTheme();

  const updateSettings = useUpdateSettings();

  const onClickNext = () =>
    updateSettings.mutateAsync({
      lcboSpecialAlerts: state,
    });

  return (
    <View style={styles.root}>
      <View style={styles.heading}>
        <BottleTitle style={styles.text} variant="displaySmall">
          {getLocaleString('lcboSpecialEvents')}
        </BottleTitle>
      </View>

      <ScrollView>
        <View style={styles.section}>
          <Text
            variant="titleLarge"
            style={{marginBottom: 20, textAlign: 'justify'}}>
            {getLocaleString('weCanAlertYouLcbo')}
          </Text>

          <ListItem
            title={
              <Text adjustsFontSizeToFit style={styles.listItemTitle}>
                {getLocaleString('allocatedOffers')}
              </Text>
            }
            description={
              <Text adjustsFontSizeToFit={true} variant="bodyLarge">
                {getLocaleString('allocatedOffersDescription')}
              </Text>
            }
            titleStyle={styles.listItemTitle}
            descriptionStyle={styles.listItemDescription}
            left={props => (
              <View style={styles.icon}>
                <Icon
                  source="slot-machine"
                  color={theme.colors.primary}
                  size={35}
                />
              </View>
            )}
            style={{
              ...styles.listItem,
              backgroundColor: `${theme.colors.primary}1F`,
            }}
          />

          <ListItem
            title={
              <Text adjustsFontSizeToFit style={styles.listItemTitle}>
                {getLocaleString('specialOffers')}
              </Text>
            }
            description={
              <Text adjustsFontSizeToFit variant="bodyLarge">
                {getLocaleString('specialOffersDescription')}
              </Text>
            }
            left={props => (
              <View style={styles.icon}>
                <Icon
                  source="treasure-chest"
                  color={theme.colors.primary}
                  size={35}
                />
              </View>
            )}
            style={{
              ...styles.listItem,
              backgroundColor: `${theme.colors.primary}1F`,
            }}
          />
        </View>

        <View style={styles.switches}>
          <BottleSwitch
            switchProps={{
              disabled: updateSettings.isLoading,
              value: state.pushNotifications,
              onValueChange: () =>
                setState({
                  ...state,
                  pushNotifications: !state.pushNotifications,
                }),
            }}
            title={getLocaleString('pushNotifications')}
            description={getLocaleString('pushNotificationsDescription')}
          />

          <BottleSwitch
            switchProps={{
              disabled: updateSettings.isLoading,
              value: state.emailAlerts,
              onValueChange: () =>
                setState({...state, emailAlerts: !state.emailAlerts}),
            }}
            title={getLocaleString('emailAlerts')}
            description={getLocaleString('emailAlertsDescription')}
          />
        </View>

        <View style={styles.footer}>
          <Text style={styles.footerText} variant="titleSmall">
            {getLocaleString('youCanChangeYourPreferencesAtAnyTime')}
          </Text>
          <BottleButton
            mode="contained"
            loading={updateSettings.isLoading}
            onPress={onClickNext}>
            {getLocaleString('next')}
          </BottleButton>
        </View>
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    height: '100%',
  },
  heading: {
    textAlign: 'center',
    marginBottom: 16,
    marginTop: 20,
  },
  icon: {
    paddingLeft: 4,
    justifyContent: 'center',
  },
  footer: {
    marginTop: 16,
  },
  section: {
    marginBottom: 10,
  },
  text: {
    textAlign: 'center',
  },
  footerText: {
    textAlign: 'center',
    marginBottom: 10,
  },
  switches: {
    paddingLeft: 8,
    paddingRight: 8,
    marginBottom: 10,
    width: '100%',
    flex: 1,
  },
  listItem: {
    marginBottom: 10,
  },
  listItemTitle: {
    fontSize: 18,
    fontWeight: 'bold',
  },
  listItemDescription: {
    fontSize: 18,
    maxHeight: '100%',
    maxWidth: '100%',
  },
});

export default LcboSpecialAlerts;
