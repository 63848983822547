import { FC, useEffect, useState } from "react";

interface MarkerProps extends google.maps.MarkerOptions {
  iconUrl: string
}

const Marker: FC<MarkerProps> = (options) => {
  const [marker, setMarker] = useState<google.maps.Marker>();

  useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker({
        icon: {
          url: options.iconUrl,
          scaledSize: new google.maps.Size(25, 25),
        }
      }));
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);

  return null;
};

export default Marker;
