import { StyleSheet } from "react-native";
import { ActivityIndicator } from "react-native-paper";

const BottleLoader = () => {
  return (
    <ActivityIndicator animating={true} size="small" style={styles.loading} />
  );
}

const styles = StyleSheet.create({
  loading: {
    margin: 'auto',
  },
});

export default BottleLoader;
