import { TranslateOptions } from "i18n-js";
import en from "../../locale/en";
import i18n from "../i18n";
import { ReactNode } from "react";

const placeholderVariables = /(?<=\^\{)[a-z,A-Z,1-9]+(?=\}\^)/gm;
const placeholderSplit = /(?:\^\{)[a-z,A-Z,1-9]+(?:\}\^)/gm;

export const getLocaleString = (
  key: keyof typeof en, options?: TranslateOptions
) => i18n.t(key, options);

export const getLocaleNodes = (
  key: keyof typeof en, options?: TranslateOptions
): string | ReactNode[] => {
  const value = i18n.t(key, options);

  if (!options) {
    return value;
  }
  const variables = Array.from(value.matchAll(placeholderVariables)).map(
    value => value[0],
  );

  if (!variables.length) {
    return value;
  }

  const segments = value.split(placeholderSplit);
  return segments.flatMap((segment, index) =>
    [segment].concat(
      index < variables.length && options[variables[index]]
        ? [options[variables[index]]]
        : [],
    ),
  );
}
