import { useGetProductInventory, WATCHDOG_INVENTORY_KEY } from "../../api/inventory";
import { HomeStackScreenProps } from "../Home/types";
import BottleScreen from '../../components/BottleScreen';
import BottleLoader from "../../components/BottleLoader";
import { InventoryTable } from "../../components/InventoryTable";
import { useGetProduct } from "../../api/lcbo";
import BottleTitle from "../../components/BottleTitle";
import { View } from "react-native";
import { useGetSettings } from "../../api/settings";
import { coordinatesDistanceMeters } from "../../utils/radius";
import { useEffect, useMemo } from "react";
import { StoreInventoryWithDistance } from "../../types";
import { queryClient } from "../../api/client";
import { WatchdogType } from "../../api/openapi";
import { Text } from "react-native-paper";
import Keyword from "../../components/Keyword";


const WatchdogInventory = ({route}: HomeStackScreenProps<'WatchdogInventory'>) => {
  const {isLoading: isProductLoading, data: product} = useGetProduct(
    route.params.id
  );
  const {isLoading, data} = useGetProductInventory('lcbo', route.params.id);
  const {isLoading: isSettingsLoading, data: settings} = useGetSettings();

  const loading = isProductLoading || isLoading || isSettingsLoading;

  const inventory: StoreInventoryWithDistance[] = useMemo<StoreInventoryWithDistance[]>(() => {
    if (!data || !settings) {
      return [];
    }
    
    return data.inventory.store_inventory
      .map(storeInventory => ({
        ...storeInventory,
        distance:
          settings.locationRadius?.latitude && settings.locationRadius.longitude
            ? coordinatesDistanceMeters(
                storeInventory.latitude,
                storeInventory.longitude,
                settings.locationRadius.latitude,
                settings.locationRadius.longitude,
              )
            : null,
      }))
      .sort((a, b) => {
        if (a.distance === b.distance) {
          return 0;
        }
        if (a.distance === null) {
          return -1;
        }
        if (b.distance === null) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        return 1;
      });
  }, [data, settings]);

  useEffect(
    () => {
      if (!product) {
        return;
      }
      // TODO: Don't hardcode LCBO type
      queryClient.invalidateQueries({
        queryKey: [WATCHDOG_INVENTORY_KEY, WatchdogType.Lcbo, product.id],
      });
    },
    [product],
  );

  return (
    <BottleScreen>
      {loading && <BottleLoader size="large" />}
      {!!data && !loading && (
        <View style={{height: '100%', paddingTop: 20}}>
          <View style={{marginBottom: 8}}>
            <BottleTitle
              variant="titleLarge"
              style={{textAlign: 'left', marginBottom: 8}}>
              {product?.name}
            </BottleTitle>
            {!!settings?.locationRadius?.latitude &&
              !!settings?.locationRadius.longitude &&
              !!settings?.locationRadius.address && (
                <Text variant="bodyMedium" numberOfLines={3}>
                  Distance From:{' '}
                  <Keyword variant="bodyMedium" numberOfLines={3}>
                    {settings.locationRadius.address}
                  </Keyword>
                </Text>
              )}
          </View>
          <InventoryTable inventory={inventory} />
        </View>
      )}
    </BottleScreen>
  );
};

export default WatchdogInventory;