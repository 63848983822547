import { useGetProductInventory } from '../../../api/inventory';
import { useGetProduct } from '../../../api/lcbo';
import { WatchdogSettingsModel, WatchdogType } from '../../../api/openapi';
import DeleteWatchdogButton from '../DeleteWatchdogButton';
import { getLocaleString } from '../../../utils/getLocaleString';
import BottleButton from '../../../components/BottleButton';
import { useAppTheme } from '../../../theme';
import { Card, Divider, Text } from 'react-native-paper';
import BottleTitle from '../../../components/BottleTitle';
import { Linking, StyleSheet, View } from 'react-native';
import StockChip from './StockChip';
import { useEffect } from 'react';

interface WatchdogCard {
  watchdog: WatchdogSettingsModel;
  onShowInventory: (productId: string) => void;
  setWatchdogLoading: (watchdogId: string, loading: boolean) => void;
}

export const WatchdogCard = ({
  onShowInventory,
  watchdog,
  setWatchdogLoading,
}: WatchdogCard) => {
  const {data: product, isLoading: isProductLoading} = useGetProduct(
    watchdog.id as string,
  );
  const {data: inventory, isLoading: isInventoryLoading} =
    useGetProductInventory(
      watchdog.type as WatchdogType,
      watchdog.id as string,
    );
  const theme = useAppTheme();

  const totalStoreInventory =
    inventory?.inventory?.store_inventory?.reduce(
      (previousValue, currentValue) =>
        (previousValue += currentValue.inventory),
      0,
    ) || 0;
  const totalInventory = totalStoreInventory + (product?.onlineInventory || 0);

  useEffect(
    () =>
      setWatchdogLoading(watchdog.id as string, isProductLoading || isInventoryLoading),
    [isProductLoading, isInventoryLoading],
  );

  return product ? (
    <View style={styles.root}>
      <Card
        style={{
          ...styles.card,
          ...{
            borderLeftColor: theme.colors.primary,
            borderLeftWidth: 2,
          },
        }}
        elevation={1}>
        <View style={styles.cardTitle}>
          <View style={{flexDirection: 'column', flex: 1}}>
            <BottleTitle variant="titleLarge" numberOfLines={3}>
              {product.name}
            </BottleTitle>
            <Text variant="bodyLarge" style={{marginTop: 4}}>
              {product.region}
              {product.region ? ', ' : ''}
              {product.country}
            </Text>
          </View>
          <DeleteWatchdogButton product={product} />
        </View>
        <Card.Cover
          source={{uri: product.imageURL}}
          accessibilityLabel={product.name}
          resizeMode="contain"
          style={styles.image}
        />
        <Card.Content style={styles.cardContent}>
          <View style={styles.details}>
            <Text
              variant="titleLarge"
              style={{fontFamily: 'monospace', fontWeight: 'bold'}}>
              {'$' + product.price.toFixed(2)}
            </Text>
            <StockChip totalInventory={totalInventory} />

            <View style={styles.detailsRight}>
              <Text variant="bodyMedium">
                {product.quantity && product.quantity > 1
                  ? product.quantity + ' x '
                  : ''}
                {product.volume ? product.volume + ' ml ' : ''}
                {product._package}
              </Text>
            </View>
          </View>
          <Divider />
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <View
              style={{
                flexDirection: 'column',
                paddingTop: 8,
                paddingBottom: 12,
              }}>
              <View
                style={{
                  marginTop: 4,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text variant="titleMedium">
                  {getLocaleString('onlineInventory')}:{' '}
                </Text>
                <Text variant="titleLarge" style={{fontWeight: 'bold'}}>
                  {product.onlineInventory}
                </Text>
              </View>
              <View
                style={{
                  marginTop: 4,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <Text variant="titleMedium">
                  {getLocaleString('storeInventory')}:{' '}
                </Text>
                <Text variant="titleLarge" style={{fontWeight: 'bold'}}>
                  {totalStoreInventory}
                </Text>
              </View>
            </View>
            {totalStoreInventory > 0 && (
              <BottleButton
                style={{padding: 0}}
                icon={'eye'}
                accessibilityLabel={getLocaleString('view')}
                onPress={() => onShowInventory(product.id)}>
                {getLocaleString('view')}
              </BottleButton>
            )}
          </View>
        </Card.Content>
        {totalInventory > 0 && (
          <BottleButton
            buttonColor={theme.colors.success}
            style={{marginTop: 4, padding: 0, width: '100%'}}
            icon={'cart'}
            accessibilityLabel={getLocaleString('buyAtLcbo')}
            onPress={() => Linking.openURL(product.uri)}
            mode="contained">
            {getLocaleString('buyAtLcbo')}
          </BottleButton>
        )}
      </Card>
    </View>
  ) : (
    <></>
  );
};

const styles = StyleSheet.create({
  root: {
    marginBottom: 20,
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    maxWidth: 500,
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  card: {
    borderLeftWidth: 1,
    width: '100%',
    maxWidth: '100%'
  },
  cardActions: {
    margin: 0,
    padding: 0,
  },
  cardContent: {
    paddingBottom: 0,
  },
  cardTitle: {
    padding: 0,
    paddingLeft: 8,
    paddingTop: 8,
    paddingBottom: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  image: {
    backgroundColor: 'white',
    height: 130,
  },
  details: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  detailsRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    marginLeft: 15,
  },
});
