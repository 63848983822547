import { QueryCache, QueryClient } from "@tanstack/react-query";
import { Auth } from "aws-amplify";

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      const status = (
        error as unknown as {response: {request: {status: number}}}
      )?.response?.request?.status;
      // TODO: I think this is what is signing people out
      if (status === 401 || status === 403) {
        Auth.currentAuthenticatedUser().catch(() => Auth.signOut());
      }
    },
  }),
});
