import { IS_MOBILE } from "../constants";
import { LINKS } from "../screens/Home/constants";
import { ANALYTICS_CONFIG } from "./analyticsConfig";
import { PUSH_CONFIG } from './pushConfig';

export const dev = {
  aws_cognito_identity_pool_id:
    'ca-central-1:713100e9-574f-4f05-ad94-6dd0af625d72',
  aws_user_pools_id: 'ca-central-1_qjsHEtddX',
  aws_user_pools_web_client_id: '29da5f9k5ov62g29e821adrj3u',
  aws_cloud_logic_custom: [
    {
      name: 'bottlehounds',
      endpoint: 'https://api.dev.bottlehounds.ca/v1',
      region: 'ca-central-1',
    },
  ],
  aws_user_files_s3_bucket: 'bottlehounds-ca-central-1-dev',
};

export const prod = {
  aws_cognito_identity_pool_id:
    'ca-central-1:0fb8dcf8-6005-4037-8a1d-e2290a4f36c6',
  aws_user_pools_id: 'ca-central-1_XRMWycKcu',
  aws_user_pools_web_client_id: 'b6k67caen5q81bk0867n7diil',
  aws_cloud_logic_custom: [
    {
      name: 'bottlehounds',
      endpoint: 'https://api.bottlehounds.ca/v1',
      region: 'ca-central-1',
    },
  ],
  aws_user_files_s3_bucket: 'bottlehounds-ca-central-1-prod',
};

const base = {
  ...(process.env.NODE_ENV === 'production' ? prod : dev),
  aws_project_region: 'ca-central-1',
  aws_cognito_region: 'ca-central-1',
  oauth: {
    domain: `auth.${
      process.env.NODE_ENV === 'production' ? '' : 'dev.'
    }bottlehounds.ca`,
    scope: ['openid', 'email'],
    redirectSignIn: LINKS[0],
    redirectSignOut: LINKS[0],
    responseType: 'code',
  },
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OPTIONAL',
  aws_cognito_mfa_types: ['TOTP'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: '12',
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket_region: 'ca-central-1',
};

export const AMPLIFY_CONFIG = {
  ...base,
  Auth: {
    identityPoolId: base.aws_cognito_identity_pool_id,
    region: base.aws_cognito_region,
    userPoolId: base.aws_user_pools_id,
    userPoolWebClient: base.aws_user_pools_web_client_id,
    oauth: base.oauth,
  },
  ...(IS_MOBILE
    ? {
        Analytics: ANALYTICS_CONFIG,
        Notifications: {
          Push: {
            ...PUSH_CONFIG,
          },
        },
      }
    : {}),
};
