import { useEffect, useRef, useState } from "react";
import { LayoutChangeEvent, View } from "react-native";
import { IconButton, Surface } from "react-native-paper";
import { MenuProps } from "./types";
import { useAppTheme } from "../../../theme";

const WebMenu = ({
  children,
  isMenuVisible: isMenuVisible,
  setIsMenuVisible,
}: MenuProps) => {
  const theme = useAppTheme();

  const [menuLeft, setMenuLeft] = useState<number>(0);
  const [menuTop, setMenuTop] = useState<number>(0);
  const menuButtonRef = useRef<View>(null);
  const menuRef = useRef<View>(null);

  const iconSize = 28;
  const width = 280;

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [menuRef, isMenuVisible]);

  const onMenuButtonLayout = (event: LayoutChangeEvent) => {
    setMenuLeft(event.nativeEvent.layout.x);
    setMenuTop(
      event.nativeEvent.layout.y + event.nativeEvent.layout.height,
    );
  };

  const handleClick = (event: MouseEvent) => {
    if (isMenuVisible && menuRef.current) {
      setIsMenuVisible(
        (menuRef.current as unknown as HTMLDivElement).contains(
          event.target as Node,
        ),
      );
    }
  };

  return (
    <View>
      <IconButton
        ref={menuButtonRef}
        size={iconSize}
        style={{
          margin: 0,
          height: 'auto',
          width: 'auto',
        }}
        iconColor={theme.colors.primary}
        icon="menu"
        onPress={() => setIsMenuVisible(!isMenuVisible)}
        onLayout={onMenuButtonLayout}
      />
      {isMenuVisible && !!menuButtonRef && (
        <Surface
          ref={menuRef}
          elevation={2}
          style={{
            position: 'absolute',
            width: width,
            left: menuLeft - width + iconSize,
            top: menuTop,
          }}>
          {children}
        </Surface>
      )}
    </View>
  );
};

export default WebMenu;