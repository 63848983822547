import { MD3DarkTheme, MD3Theme, useTheme } from "react-native-paper";``

export const theme: MD3Theme = {
  ...MD3DarkTheme,
  dark: true,
  roundness: 0,
  colors: {
    ...MD3DarkTheme.colors,
    primary: '#DEB550',
    secondary: '#FFFFFF',
    error: '#FF3333',
    surface: '#000000',
    background: '#000000',
    onBackground: '#f3f3f3',
    onSurface: '#f3f3f3',
    onSurfaceVariant: '#f3f3f3',
    onPrimary: '#000000',
    onSecondary: '#000000',
    elevation: {
      ...MD3DarkTheme.colors.elevation,
      level1: '#DEB5501D',
    },
  },
  version: 3,
};

export const appTheme = {
  ...theme,
  ...{
    colors: {
      ...theme.colors,
      warning: '#ff8c00',
      success: '#90ee90',
      hover: '#DEB5502D',
    },
  },
};

export type AppTheme = typeof appTheme;
export const useAppTheme = () => useTheme<AppTheme>();
