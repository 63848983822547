import { Auth, Hub, Notifications } from "aws-amplify";
import type {AWSPinpointUserInfo} from '@aws-amplify/notifications';
import { CognitoUser } from "@aws-amplify/auth"
import { ReactElement, useEffect, useState } from "react";
import { NavigationContainer, Theme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";

import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Image, StyleSheet } from "react-native";
import NewPasswordRequried from "./SignIn/NewPasswordRequired";
import { AuthRoute, AuthStackParamList } from "./types";
import { getLocaleString } from "../../utils/getLocaleString";
import { IS_MOBILE } from "../../constants";
import ForgotPassword from "./ForgotPassword";

interface AuthStackProps {
  children: ReactElement;
  initialStep?: AuthRoute;
  theme: Theme;
}

const Stack = createStackNavigator<AuthStackParamList>();

const AuthStack = ({
  children,
  initialStep = "SignIn",
  theme,
}: AuthStackProps) => {
  const [user, setUser] = useState<CognitoUser|null>();
  const [loading, setLoading] = useState(true);

  const linking = {
    prefixes: [
      'https://app.bottlehounds.ca/',
      'https://app.dev.bottlehounds.ca/',
      'http://localhost:8080/',
    ],
    config: {
      screens: {
        SignIn: '/signin',
        SignUp: '/signup',
        ForgotPassword: '/forgot-password',
        NewPasswordRequired: '/reset-password',
      },
    },
  };

  const handlePushPermissions = async () => {
    const status = await Notifications.Push.getPermissionStatus();
    if (status === 'SHOULD_REQUEST') {
      await Notifications.Push.requestPermissions();
    } else if (status === 'SHOULD_EXPLAIN_THEN_REQUEST') {
      // you should display some explanation to your user before requesting permissions
      await Notifications.Push.requestPermissions();
    }
  };

  useEffect(() => {
    Hub.listen("auth", ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          Auth.currentAuthenticatedUser().then(user => setUser(user));
          break;
        case 'signOut':
          setUser(null);
          setLoading(false);
          break;
        case 'tokenRefresh':
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setUser(user);
      })
      .catch(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    if (IS_MOBILE) {
      handlePushPermissions();
      Notifications.Push.identifyUser(
        user.getUsername(), 
        { optOut: 'NONE' } as AWSPinpointUserInfo
      );
    }
  }, [user])

  return user ? (
    children
  ) : (
    <>
      {!loading && (
        <NavigationContainer
          linking={linking}
          theme={theme}
          documentTitle={{
            enabled: true,
            formatter: (options, route) =>
              `Bottle Hounds | ${options?.title ?? route?.name}`,
          }}>
          <Stack.Navigator
            initialRouteName={initialStep}
            screenOptions={{
              header: props => (
                <Image
                  style={styles.image}
                  source={require('../../../assets/bottlehounds_logo-min.png')}
                  accessibilityLabel={getLocaleString('bottlehoundsLogo')}
                  resizeMode="contain"
                  {...props}
                />
              ),
              headerMode: 'screen',
              cardStyle: styles.steps,
              presentation: 'card',
            }}>
            <Stack.Screen
              name="SignIn"
              component={SignIn}
              options={{title: getLocaleString('signIn')}}
            />
            <Stack.Screen
              name="SignUp"
              component={SignUp}
              options={{title: getLocaleString('signUp')}}
            />
            <Stack.Screen
              name="NewPasswordRequired"
              component={NewPasswordRequried}
            />
            <Stack.Screen
              name="ForgotPassword"
              component={ForgotPassword}
              options={{title: getLocaleString('forgotPassword')}}
            />
          </Stack.Navigator>
        </NavigationContainer>
      )}
    </>
  );
};

const styles = StyleSheet.create({
  image: {
    height: 120,
    width: 120,
    position: "relative",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: 20,
    marginTop: "5%",
  },
  steps: {
    flex: 1,
    marginTop: 16,
    padding: 4,
    margin: "auto",
    width: "100%",
  },
});

export default AuthStack;
