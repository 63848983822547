import { useState } from "react";
import {CognitoUser} from '@aws-amplify/auth';
import { Subheading, useTheme } from "react-native-paper";
import { Auth } from "aws-amplify";
import { StyleSheet } from "react-native";
import { TextInput } from "react-native-paper";
import { AuthStackParamList } from "../types";
import { getLocaleString } from "../../../utils/getLocaleString";
import BottleButton from "../../../components/BottleButton";
import { StackNavigationProp } from "@react-navigation/stack";
import AuthScreen from "../AuthScreen";


type NewPasswordRequired = {
  user: CognitoUser;
  navigation: StackNavigationProp<AuthStackParamList>;
};


const NewPasswordRequried = ({user, navigation}: NewPasswordRequired) => {
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);

  const {colors} = useTheme();

  const submitDisabled = !password || password !== confirmPassword || loading;

  const confirmPasswordError = !!(
    confirmPassword && confirmPassword !== password
  );

  const submit = () => {
    setLoading(true);
    setError('');
    setPasswordError('');

    Auth.completeNewPassword(user, password)
      .then(user => {
        setLoading(false);
      })
      .catch(error => {
        switch (error.code) {
          case 'InvalidPasswordException':
            setError(getLocaleString('invalidPassword'));
            setPassword('');
            setConfirmPassword('');
            break;
          default:
            navigation.push('SignIn', {error: getLocaleString('sww')});
            break;
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <AuthScreen>
      {!!error && (
        <Subheading style={{color: colors.error, textAlign: 'center'}}>
          {error}
        </Subheading>
      )}

      <TextInput
        autoComplete="off"
        dense={true}
        error={!!passwordError}
        label={getLocaleString('password')}
        mode="outlined"
        onChangeText={setPassword}
        secureTextEntry={true}
        value={password}
        disabled={loading}
      />

      <TextInput
        autoComplete="off"
        dense={true}
        label={getLocaleString('confirmPassword')}
        mode="outlined"
        onChangeText={setConfirmPassword}
        secureTextEntry={true}
        value={confirmPassword}
        disabled={loading}
        blurOnSubmit={true}
        onSubmitEditing={submit}
        returnKeyType="send"
        error={confirmPasswordError}
      />
      <BottleButton
        disabled={submitDisabled}
        mode="contained"
        onPress={submit}
        style={styles.button}>
        {getLocaleString('changePassword')}
      </BottleButton>
    </AuthScreen>
  );
};

const styles = StyleSheet.create({
  button: {
    marginTop: 10,
  },
});

export default NewPasswordRequried;
