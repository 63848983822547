export const PINPOINT_APP_ID = 
  process.env.NODE_ENV === 'production'
  ? '' : '50f4fcd6299a4b3d95162173f42b0105';

export const ANALYTICS_CONFIG = {
  AWSPinpoint: {
    appId: PINPOINT_APP_ID,
    region: 'ca-central-1',
    endpoint: {
      optOut: 'NONE',
    }
  },
};
