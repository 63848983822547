import {StyleSheet, View} from 'react-native';
import BottleLocation from '../../components/BottleLocation';

const LocationSettings = () => {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 4,
        marginTop: 20,
      }}>
      <View style={{maxWidth: 500, width: '100%'}}>
        <View style={styles.section}>
          <BottleLocation />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    width: '100%',
    paddingTop: 4,
    paddingLeft: 4,
    paddingBottom: 4,
  },
  section: {
    marginBottom: 10,
    width: '100%',
  },
});

export default LocationSettings;
