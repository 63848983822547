import {useState} from 'react';
import {Text} from 'react-native-paper';
import {StyleSheet, View} from 'react-native';
import {getLocaleString} from '../../utils/getLocaleString';
import {WelcomeScreenProps} from './types';
import BottleLocation from '../../components/BottleLocation';
import BottleTitle from '../../components/BottleTitle';

const Location = ({setStep}: WelcomeScreenProps) => {
  const [placeId, setPlaceId] = useState<string>('');

  const onSave = () => setStep('alerts');

  return (
    <View style={styles.root}>
      {!placeId && (
        <>
          <View style={styles.heading}>
            <BottleTitle style={styles.centerText} variant="displaySmall">
              {getLocaleString('centerYourSearch')}
            </BottleTitle>
          </View>

          <Text style={{textAlign: 'justify'}} variant="headlineSmall">
            {getLocaleString('keepAlertsRelativeToYou')}
          </Text>
        </>
      )}

      {!!placeId && (
        <View style={{marginTop: 25, marginBottom: 10}}>
          <BottleTitle style={styles.centerText} variant="displaySmall">
            {getLocaleString('setYourBounds')}
          </BottleTitle>
        </View>
      )}

      <View
        style={{
          width: '100%',
          maxWidth: 520,
          flex: 1,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'row',
        }}>
        <BottleLocation
          bottomText={getLocaleString('youCanChangeYourPreferencesAtAnyTime')}
          onPlaceSelected={setPlaceId}
          onSave={onSave}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    overflow: 'visible',
    width: '100%',
  },
  welcome: {
    textAlign: 'center',
    textDecorationLine: 'underline',
    marginBottom: 40,
  },
  heading: {
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 25,
  },
  helper: {
    textAlign: 'center',
    marginBottom: 5,
  },
  searchHeading: {
    marginTop: 10,
    marginBottom: 10,
    justifyContent: 'center',
  },
  centerText: {
    textAlign: 'center',
  },
});

export default Location;
