import { Platform } from "react-native";
import MobileMenu from "./MobileMenu";
import { MenuProps } from "./types";
import WebMenu from "./WebMenu";

const BottleMenu = (props: MenuProps) => {
  return Platform.OS === 'web' ? (
    <WebMenu {...props} />
  ) : (
    <MobileMenu {...props} />
  );
}

export default BottleMenu;