import {Wrapper} from '@googlemaps/react-wrapper';
import {useTheme} from 'react-native-paper';
import {mapStyle} from '../mapstyle';
import {MapProps} from '../types';
import Circle from './Circle';
import Map from './Map';
import Marker from './Marker';

const WebMap = ({radius, region, onMapLoaded}: MapProps) => {
  const theme = useTheme();
  const webRegion: google.maps.LatLngLiteral = {
    lat: region.latitude,
    lng: region.longitude,
  };

  const style = {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
  };

  return (
    <Wrapper apiKey={'AIzaSyAiwuOhu0FhSkGl_9-yHcwkMWPJEamHxHU'}>
      <Map
        onMapLoaded={onMapLoaded}
        radius={radius > 0 ? radius : 150000}
        center={webRegion}
        style={style}
        styles={mapStyle}
        zoomControl={false}
        streetViewControl={false}
        fullscreenControl={false}
        scaleControl={false}
        keyboardShortcuts={false}
        mapTypeControl={false}
        scrollwheel={false}
        disableDoubleClickZoom={false}
        disableDefaultUI={false}
        draggable={false}
        gestureHandling={null}>
        {radius !== 0 && (
          <Circle
            center={webRegion}
            radius={radius}
            strokeWeight={1}
            strokeColor={theme.colors.primary}
            fillColor={theme.colors.primary}
            fillOpacity={0.1}
          />
        )}
        <Marker
          position={webRegion}
          iconUrl={require('../../../../../assets/bottlehounds_logo-min.png')}
        />
      </Map>
    </Wrapper>
  );
};

export default WebMap;
