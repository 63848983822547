import { ScrollView, View, ViewProps } from "react-native";

export type BottleScreenProps = {
  maxWidth?: number;
  innerMaxWidth?: number;
} & ViewProps

const BottleScreen = ({
  children,
  maxWidth = 800,
  innerMaxWidth = 800,
}: BottleScreenProps) => (
  <View
    style={{
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      height: '100%',
      width: '100%',
      maxWidth,
      marginLeft: 'auto',
      marginRight: 'auto',
    }}>
    <ScrollView
      showsVerticalScrollIndicator
      contentContainerStyle={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        paddingLeft: 4,
        paddingRight: 4,
      }}>
      <View style={{height: '100%', width: '100%', maxWidth: innerMaxWidth}}>
        {children}
      </View>
    </ScrollView>
  </View>
);

export default BottleScreen;
