import { API } from 'aws-amplify';
import { BOTTLEHOUNDS_API_NAME } from './constants';
import {
  SettingsResponseModel,
  WatchdogRequestModel
} from './openapi';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SETTINGS_API_PATH } from './settings';

export const WATCHDOGS_API_PATH = '/watchdogs'

export const addWatchdog = (request: WatchdogRequestModel) => API.post(
  BOTTLEHOUNDS_API_NAME,
  WATCHDOGS_API_PATH,
  {body: request},
) as Promise<SettingsResponseModel>

export const deleteWatchdog = (watchdogId: string) => API.del(
  BOTTLEHOUNDS_API_NAME,
  `${WATCHDOGS_API_PATH}/${watchdogId}`,
  {},
)

export const useAddWatchdog = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addWatchdog,
    onSuccess: () => 
        queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  })
}

export const useDeleteWatchdog = () =>{
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteWatchdog,
    onSuccess: () =>
      queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]}),
  });
}
