import { FC, useEffect, useState } from "react";

const Circle: FC<google.maps.CircleOptions> = (options) => {
  const [Circle, setCircle] = useState<google.maps.Circle>();

  useEffect(() => {
    if (!Circle) {
      setCircle(new google.maps.Circle(options));
    }

    return () => {
      if (Circle) {
        Circle.setMap(null);
      }
    };
  }, [Circle]);

  useEffect(() => {
    if (Circle) {
      Circle.setOptions(options);
    }
  }, [Circle, options]);

  return null;
};

export default Circle;
