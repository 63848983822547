import React from 'react'
import { createRoot } from 'react-dom/client'
import App from '../src/App'

import iconFont from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'

const iconFontStyles = `@font-face {
  src: url(${iconFont});
  font-family: MaterialCommunityIcons;
}`

const style = document.createElement('style')
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles
} else {
  style.appendChild(document.createTextNode(iconFontStyles))
}

document.head.appendChild(style)

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
