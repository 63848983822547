import { useGetProductInventory } from '../../api/inventory';
import { useGetProduct } from '../../api/lcbo';
import { WatchdogSettingsModel, WatchdogType } from '../../api/openapi';
import { BottleCard } from '../../components/BottleCard';
import DeleteWatchdogButton from './DeleteWatchdogButton';
import { getLocaleString } from '../../utils/getLocaleString';
import BottleButton from '../../components/BottleButton';

interface WatchdogCard {
  watchdog: WatchdogSettingsModel;
  onShowInventory: (inventory: StoreInventoryModel[]) => void;
}

export const WatchdogCard = ({
  onShowInventory,
  watchdog,
}: WatchdogCard) => {
  const {data: product, isLoading: isProductLoading} = useGetProduct(
    watchdog.id as string,
  );
  const {data: inventory, isLoading: isInventoryLoading} =
    useGetProductInventory(
      watchdog.type as WatchdogType,
      watchdog.id as string,
    );

  console.log(inventory);

  return product ? (
    <BottleCard
      action={<DeleteWatchdogButton product={product} />}
      product={product}
      bottomAction={
        !!inventory?.store_inventory && (
          <BottleButton
            style={{padding: 0, width: '100%'}}
            icon={'eye'}
            accessibilityLabel={getLocaleString('viewInventory')}
            onPress={() => onShowInventory(inventory.store_inventory)}
            mode="contained">
            {getLocaleString('viewInventory')}
          </BottleButton>
        )
      }
    />
  ) : (
    <></>
  );
};
