import { useMemo, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { FlatList, View, StyleSheet, ListRenderItem, RefreshControl } from "react-native";
import { Text } from "react-native-paper";
import { HomeStackScreenProps } from "../Home/types";
import { useGetSettings } from "../../api/settings";
import { WatchdogSettingsModel } from "../../api/openapi";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import BottleLoader from "../../components/BottleLoader";
import BottleButton from "../../components/BottleButton";
import Keyword from "../../components/Keyword";
import WatchdogCard from "./WatchdogCard";
import { queryClient } from "../../api/client";
import { WATCHDOG_INVENTORY_KEY } from "../../api/inventory";
import Search from "../Search";


const Watchdogs = ({ navigation }: HomeStackScreenProps<'Watchdogs'>) => {
  const [watchdogsLoading, setWatchdogsLoading] = useState<{[key in string]: boolean}>({});
  const { data: settings, isLoading } = useGetSettings();
  const watchdogsAvailable = (
    settings ?
    settings.maxWatchdogs - settings.watchdogs.length :
    0
  );
  
  const onRefresh = () => {
    settings?.watchdogs.forEach((watchdog) => queryClient.invalidateQueries({
      queryKey: [WATCHDOG_INVENTORY_KEY, watchdog.type, watchdog.id]
    }))
  }

  const onShowInventory = (productId: string) => {
    navigation.push('WatchdogInventory', {id: productId});
  };

  const setWatchdogLoading = (watchdogId: string, loading: boolean) => {
    setWatchdogsLoading({
      ...watchdogsLoading,
      [watchdogId]: loading,
    });
  }

  const renderItem: ListRenderItem<WatchdogSettingsModel> = ({ item }) => {
    return (
      <WatchdogCard
        key={item.id as string}
        onShowInventory={onShowInventory}
        watchdog={item}
        setWatchdogLoading={setWatchdogLoading}
      />
    );
  };

  const refreshing = useMemo<boolean>(
    () => Object.values(watchdogsLoading).some(Boolean) || isLoading,
    [watchdogsLoading]
  )

  return (
    <SafeAreaView style={styles.container}>
      {isLoading && <BottleLoader size="large" />}

      {
        !isLoading && settings?.watchdogs && settings.watchdogs.length === 0 && <Search />
      }

      {!isLoading && !!settings?.watchdogs.length && (
        <>
          <FlatList
            data={settings?.watchdogs}
            renderItem={renderItem}
            keyExtractor={item => item.id as string}
            style={styles.results}
            refreshControl={
              <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
            }
          />

          <View style={styles.addButton}>
            {watchdogsAvailable > 0 && (
              <Text
                variant="titleLarge"
                style={{paddingBottom: 8, textAlign: 'center'}}>
                {getLocaleNodes('youHaveNMoreWatchdogsAvailable', {
                  watchdogsAvailable: <Keyword>{watchdogsAvailable}</Keyword>,
                  watchdogs: <Keyword>Watchdogs</Keyword>,
                })}
              </Text>
            )}
            <BottleButton
              icon="plus"
              mode="contained"
              onPress={() => {
                navigation.push('Search');
              }}>
              {getLocaleString('addWatchdog')}
            </BottleButton>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
    height: "100%",
  },
  addButton: {
    marginTop: 10,
    marginBottom: 15,
  },
  results: {
    flex: 1
  },
  loading: {
    margin: "auto",
  },
});

export default Watchdogs;
