import "react-native-gesture-handler";
import "i18n-js";
import { DarkTheme as NavigationDarkTheme } from "@react-navigation/native";
import {
  Provider as PaperProvider,
  Text,
  adaptNavigationTheme,
} from "react-native-paper";
import { Linking, Platform, StyleSheet, View } from "react-native";
import AuthStack from "./screens/AuthStack";
import Home from "./screens/Home";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { QueryClientProvider } from "@tanstack/react-query";
import {Amplify, Notifications} from 'aws-amplify';
import { PushNotificationMessage } from '@aws-amplify/rtn-push-notification'
import { AMPLIFY_CONFIG } from "./aws/config";
import { IS_MOBILE } from "./constants";
import { enableLatestRenderer } from "react-native-maps";
import { queryClient } from "./api/client";
import { appTheme } from "./theme";

Amplify.configure(AMPLIFY_CONFIG);

if (IS_MOBILE) {
  enableLatestRenderer();
  Notifications.Push.enable();

  const handleNotification = (notification: PushNotificationMessage) => {
    if (notification.goToUrl) {
      Linking.openURL(notification.goToUrl);
    }
  };
  Notifications.Push.onNotificationOpened(handleNotification);
  Notifications.Push.getLaunchNotification().then(
    (notification: PushNotificationMessage | null) => {
      if (notification) {
        handleNotification(notification);
      }
    }
  );
}

window.onunhandledrejection = event => {
  console.error(event);
};

const {DarkTheme: navigationTheme} = adaptNavigationTheme({
  reactNavigationDark: NavigationDarkTheme,
  materialDark: appTheme,
});

const App = () => {
  return (
    <SafeAreaProvider>
      <PaperProvider theme={appTheme}>
        <SafeAreaView style={styles.root}>
          <View style={styles.content}>
            <View style={{flexGrow: 1}}>
              <AuthStack theme={navigationTheme}>
                <QueryClientProvider client={queryClient}>
                  <Home theme={navigationTheme} />
                </QueryClientProvider>
              </AuthStack>
            </View>
            {Platform.OS === 'web' && (
              <View>
                <Text
                  variant="bodySmall"
                  style={{
                    textAlign: 'center',
                    paddingTop: 4,
                    paddingBottom: 8,
                  }}>
                  Bottle Hounds Inc. © 2024
                </Text>
              </View>
            )}
          </View>
        </SafeAreaView>
      </PaperProvider>
    </SafeAreaProvider>
  );
};

const styles = StyleSheet.create({
  root: {
    flex: 1,
    height: '100%',
  },
  content: {
    maxWidth: 1024,
    width: "100%",
    flex: 1,
    margin: "auto",
    overflow: 'visible',
    height: '100%',
  },
});

export default App;
