import { useEffect, useState } from "react";
import { FlatList, View, StyleSheet, ListRenderItem } from "react-native";
import { ActivityIndicator, useTheme } from "react-native-paper";
import { BottleCard } from "./BottleCard";
import { useSearchProducts } from "../api/lcbo";
import { ProductModel } from "../api/openapi";
import { useAddWatchdog } from "../api/watchdogs";
import { useGetSettings } from "../api/settings";
import Searchbar from "../design-system/SearchBar";
import { getLocaleString } from "../utils/getLocaleString";
import BottleButton from "./BottleButton";


type BottleSearchProps =
  | {
      onboarding: true;
      onAdd: () => void;
      onSearch: () => void;
    }
  | {
      onboarding: false;
      onAdd: undefined;
      onSearch: undefined;
    };

const BottleSearch = ({onAdd, onSearch}: BottleSearchProps) => {
  const [input, setInput] = useState('');
  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [searchResults, setSearchResults] = useState<ProductModel[]>([]);
  const [totalResults, setTotalResults] = useState<number>();
  const [productLoading, setProductLoading] = useState<string | null>();

  const theme = useTheme();
  const addWatchdog = useAddWatchdog();
  const searchProducts = useSearchProducts(
    {
      query,
      skip,
    },
    !!query,
  );
  const {data: settings} = useGetSettings();

  const productIds = settings?.watchdogs.map(watchdog => watchdog.id) ?? [];

  useEffect(() => {
    if (searchProducts.isSuccess && searchProducts.data) {
      setSearchResults(searchResults.concat(searchProducts.data.products));
      setTotalResults(searchProducts.data.total);
    }
  }, [searchProducts.isSuccess && searchProducts.data]);

  const addProduct = async (product: ProductModel) => {
    setProductLoading(product.id);
    await addWatchdog.mutateAsync({product});
    setProductLoading(null);
    if (onAdd) {
      onAdd();
    }
  };

  const clear = () => {
    setInput('');
    setQuery('');
    setTotalResults(0);
    setSearchResults([]);
  };

  const search = () => {
    if (onSearch) {
      onSearch();
    }
    setSearchResults([]);
    setTotalResults(0);
    setQuery(input.trim());
    setSkip(0);
  };

  const onEndReached = () => {
    if (searchResults.length !== totalResults && !searchProducts.isFetching)
      setSkip(searchResults.length);
  };

  const Loadingcomponent = (
    <ActivityIndicator
      animating={searchProducts.isFetching}
      size="large"
      style={styles.loading}
    />
  );

  const renderItem: ListRenderItem<ProductModel> = ({item}) => {
    const isActiveWatchdog = productIds.includes(item.id);
    return (
      <BottleCard
        bottomAction={
          <BottleButton
            style={styles.itemButton}
            icon={isActiveWatchdog ? '' : 'plus'}
            accessibilityLabel={getLocaleString('track')}
            onPress={() => addProduct(item)}
            disabled={!!productLoading || isActiveWatchdog}
            loading={productLoading == item.id}
            mode="contained">
            {isActiveWatchdog
              ? getLocaleString('active')
              : getLocaleString('track')}
          </BottleButton>
        }
        product={item}
      />
    );
  };

  return (
    <View style={styles.container}>
      <Searchbar
        autoComplete="off"
        placeholder={getLocaleString('bottleSearchExample')}
        onChangeText={setInput}
        value={input}
        onIconPress={search}
        onSubmitEditing={search}
        clearIcon="close"
        onClearIconPress={clear}
        style={styles.searchBar}
        mode="bar"
        inputStyle={{
          color: theme.colors.onSurface,
          fontSize: 18,
        }}
        placeholderTextColor="#eeeeee"
      />
      <FlatList
        data={searchResults}
        renderItem={renderItem}
        keyExtractor={product => product.id}
        extraData={productLoading}
        onEndReached={onEndReached}
        onEndReachedThreshold={1}
        refreshing={searchProducts.isFetching}
        style={styles.results}
        ListEmptyComponent={Loadingcomponent}
        ListFooterComponent={
          searchResults.length && searchProducts.isFetching
            ? Loadingcomponent
            : null
        }
        ListFooterComponentStyle={styles.footer}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexGrow: 1,
    height: '100%',
  },
  loading: {
    flexGrow: 1,
    margin: 'auto',
  },
  results: {
    height: '100%',
  },
  searchBar: {
    backgroundColor: 'black',
    borderBottomWidth: 1,
    borderBottomColor: 'white',
    marginBottom: 10,
  },
  footer: {
    margin: 'auto',
    height: 80,
  },
  itemButton: {
    width: '100%',
    padding: 0,
  },
});

export default BottleSearch;
