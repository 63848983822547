import { ScrollView, StyleSheet, View } from "react-native";
import { Modal, Portal, RadioButton, Surface, Text } from "react-native-paper";
import BottleButton from "../BottleButton";
import { getLocaleNodes, getLocaleString } from "../../utils/getLocaleString";
import Keyword from "../Keyword";
import { useAppTheme } from "../../theme";
import { ProductModel, WatchdogSettingsModel } from "../../api/openapi";
import { useState } from "react";
import BottleListItem from "../BottleListItem";

interface SwapPortalProps {
  loading: boolean;
  product: ProductModel;
  watchdogs: WatchdogSettingsModel[];
  swapWatchdog: (watchdogIdToDelete: string) => void;
  close: () => void;
}

const SwapPortal = ({
  loading,
  product,
  watchdogs,
  swapWatchdog,
  close,
}: SwapPortalProps) => {
  const [watchdogToDelete, setWatchdogToDelete] = useState<WatchdogSettingsModel>();
  const theme = useAppTheme();

  return (
    <Portal>
      <Modal
        visible={true}
        onDismiss={close}
        style={styles.modal}
        contentContainerStyle={styles.modal}>
        <Surface
          style={{
            borderColor: theme.colors.primary15,
            borderWidth: 1,
            borderTopColor: theme.colors.primary,
            borderTopWidth: 4,
            backgroundColor: theme.colors.primary5,
            ...styles.content,
          }}>
          {
            <View
              style={{
                ...styles.content,
                padding: 0,
              }}>
              <Text style={styles.title} variant="titleLarge">
                {getLocaleNodes('swapWatchdog', {
                  watchdog: (
                    <Keyword key={product.id} variant="titleLarge">
                      {product.name}
                    </Keyword>
                  ),
                })}
              </Text>

              <ScrollView
                style={{
                  padding: 4,
                  marginTop: 8,
                  width: '100%',
                  maxHeight: 300,
                }}>
                {watchdogs.map(watchdog => (
                  <BottleListItem
                    title={
                      <View
                        style={{
                          flexDirection: 'row',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}>
                        <RadioButton.Item
                          key={watchdog.id}
                          label=""
                          value="plan"
                          style={{padding: 0, margin: 0, paddingRight: 8}}
                          status={
                            watchdogToDelete?.id === watchdog.id
                              ? 'checked'
                              : 'unchecked'
                          }
                        />
                        <Text variant="titleMedium" numberOfLines={2}>
                          {watchdog.name}
                        </Text>
                      </View>
                    }
                    onPress={() => setWatchdogToDelete(watchdog)}
                    titleStyle={{padding: 0}}
                    contentStyle={{padding: 0, paddingLeft: 4}}
                  />
                ))}
              </ScrollView>
            </View>
          }
          {/* {!!watchdogToDelete && (
            <Text style={styles.title} variant="titleLarge">
              {getLocaleNodes('deleteFromList', {
                watchdog: (
                  <Keyword key={watchdogToDelete.id} variant="titleLarge">
                    {watchdogToDelete.name}
                  </Keyword>
                ),
              })}
            </Text>
          )} */}

          <View style={styles.actions}>
            <BottleButton
              textColor={theme.colors.secondary}
              disabled={loading}
              onPress={close}>
              {getLocaleString('cancel')}
            </BottleButton>
            <BottleButton
              textColor={theme.colors.error}
              disabled={loading || !watchdogToDelete}
              loading={loading}
              onPress={() => swapWatchdog(watchdogToDelete?.id as string)}>
              {getLocaleString('swap')}
            </BottleButton>
          </View>
        </Surface>
      </Modal>
    </Portal>
  );
};

const styles = StyleSheet.create({
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
  },
  content: {
    width: '100%',
    minHeight: 100,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'space-between',
    padding: 16,
    textAlign: 'center',
  },
  modal: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 4,
    paddingRight: 4,
    width: '100%',
    maxWidth: 600,
    margin: 'auto',
  },
  title: {
    marginBottom: 10,
    textAlign: 'justify',
  },
});

export default SwapPortal;
