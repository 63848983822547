import {API} from 'aws-amplify';
import {BOTTLEHOUNDS_API_NAME} from './constants';
import {useQuery} from '@tanstack/react-query';
import { Place, PlaceAutocompleteResult } from '@googlemaps/google-maps-services-js';
import { GetPlacesMapsPlacesGetRequest } from './openapi/apis';

export const MAPS_API_PATH = '/maps/places';

export const autocompletePlace = (request: GetPlacesMapsPlacesGetRequest) =>
  API.get(
    BOTTLEHOUNDS_API_NAME,
    MAPS_API_PATH,
    {
      queryStringParameters: request,
    },
  ) as Promise<PlaceAutocompleteResult[]>;

export const getPlace = (placeId: string): Promise<Place> =>
  API.get(BOTTLEHOUNDS_API_NAME, `${MAPS_API_PATH}/${placeId}`, {})

export const useAutocompletePlace = (
  request: GetPlacesMapsPlacesGetRequest,
) =>
  useQuery({
    queryKey: [MAPS_API_PATH, request],
    queryFn: () => autocompletePlace(request),
    enabled: !!request.query,
  })

export const useGetPlace = (placeId: string) =>
  useQuery({
    queryKey: [MAPS_API_PATH, placeId],
    queryFn: () => getPlace(placeId),
    enabled: !!placeId, 
  })
