import { useEffect, useState } from "react";
import { Linking, ScrollView, View } from "react-native";
import { SubscriptionPlanEnum } from "../../api/openapi";
import PlanCard from "./PlanCard";
import { Icon, Text, useTheme } from "react-native-paper";
import { getLocaleString } from "../../utils/getLocaleString";
import BottleTitle from "../../components/BottleTitle";
import { useCreateSubscriptionCheckoutSession, useGetSubscriptionPlans } from "../../api/subscription";
import { SETTINGS_API_PATH, useGetSettings } from "../../api/settings";
import { queryClient } from "../../api/client";
import { HomeStackScreenProps } from "../Home/types";
import BottleLoader from "../../components/BottleLoader";
import BottleButton from "../../components/BottleButton";
import BottleScreen from "../../components/BottleScreen";

const Subscribe = ({navigation, route}: HomeStackScreenProps<'Subscribe'>) => {
  const theme = useTheme();
  const [selectedPlanKey, setSelectedPlanKey] =
    useState<SubscriptionPlanEnum>('standard_monthly');
  const [checkoutPending, setCheckoutPending] = useState(
    route.params?.['session'] === 'success'
  );

  const {isLoading, data} = useGetSubscriptionPlans();
  const {isLoading: isSettingsLoading, data: settings} = useGetSettings();
  const createSubscriptionCheckoutSession =
    useCreateSubscriptionCheckoutSession();

  const userIsActive = !!(
    settings?.subscription?.subscriptionStatus === 'active' ||
    settings?.subscription?.subscriptionStatus === 'trialing'
  );

  const onPress = async () => {
    setCheckoutPending(true);
    const result = await createSubscriptionCheckoutSession.mutateAsync(
      selectedPlanKey,
    );
    Linking.openURL(result.checkoutSessionUrl);
  };

  useEffect(() => {
    if (userIsActive) {
      navigation.push('Watchdogs');
    }
  }, [checkoutPending, settings]);

  useEffect(() => {
    if (!userIsActive && checkoutPending && !isSettingsLoading) {
      setTimeout(() => {
        queryClient.invalidateQueries({queryKey: [SETTINGS_API_PATH]});
      }, 3000);
    }
  }, [checkoutPending, isLoading, userIsActive]);

  return (
    <BottleScreen>
      {(isLoading || isSettingsLoading || checkoutPending) && <BottleLoader />}
      {!checkoutPending && !isLoading && data && (
        <View style={{height: '100%', width: '100%'}}>
          <View style={{marginBottom: 4, marginTop: 15, alignItems: 'center'}}>
            <BottleTitle variant="displaySmall" style={{textAlign: 'center'}}>
              {getLocaleString('chooseYourPlan')}
            </BottleTitle>
          </View>

          <ScrollView style={{height: '100%', width: '100%'}}>
            <View
              style={{
                width: '100%',
                maxWidth: 600,
                padding: 4,
                margin: 'auto',
              }}>
              <View
                style={{
                  width: '100%',
                  marginBottom: 15,
                }}>
                <View
                  style={{paddingLeft: 10, paddingRight: 5, paddingBottom: 15}}>
                  <Text
                    variant="titleLarge"
                    style={{marginTop: 12, marginBottom: 4}}>
                    {getLocaleString('eachPlanIncludes')}
                  </Text>
                  <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                    <Icon
                      source="check"
                      size={25}
                      color={theme.colors.primary}
                    />
                    <Text
                      style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                      variant="titleMedium">
                      {getLocaleString('thirtyDayFreeTrial')}
                    </Text>
                  </View>
                  <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                    <Icon
                      source="check"
                      size={25}
                      color={theme.colors.primary}
                    />
                    <Text
                      style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                      variant="titleMedium">
                      {getLocaleString('frequentInventoryChecks')}
                    </Text>
                  </View>
                  <View style={{paddingLeft: 4, flexDirection: 'row'}}>
                    <Icon
                      source="check"
                      size={25}
                      color={theme.colors.primary}
                    />
                    <Text
                      style={{marginLeft: 5, flex: 1, flexWrap: 'wrap'}}
                      variant="titleMedium">
                      {getLocaleString('realTimeAlerting')}
                    </Text>
                  </View>
                </View>
                {data.plans.map(plan => (
                  <PlanCard
                    key={plan.plan}
                    plan={plan}
                    selected={selectedPlanKey === plan.plan}
                    setPlan={setSelectedPlanKey}
                  />
                ))}
              </View>
              <View style={{marginTop: 10}}>
                <BottleButton
                  mode="contained"
                  disabled={!selectedPlanKey}
                  onPress={onPress}>
                  {getLocaleString('startFreeTrial')}
                </BottleButton>
              </View>
            </View>
          </ScrollView>
        </View>
      )}
    </BottleScreen>
  );
};

export default Subscribe;
